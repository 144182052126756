import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { InputAdornment, Select } from '@material-ui/core';
import MessageBox from '~components/forms/MessageBox';
import Container from '~components/Container';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import Link from '~components/Link';
import TextInput from '~components/form-inputs/TextInput';

import StepFourImg from '~images/onboarding/step-4.jpg';

const useStyles = makeStyles(theme => ({
  media: {
    maxWidth: '100%',
    display: 'flex',
    marginBottom: theme.spacing(3)
  },
  form: {
    width: '100%'
  }
}));

const OnboardingStepFour = ({
  handleSubmit,
  isError,
  isValidationError,
  error,
  validationErrorMessage,
  availableCountryCodes,
  countryCode,
  onCountryCodeChange,
  registerReactHookFormInput,
  previousPath,
  previousLocationState
}) => {
  const classes = useStyles();

  return (
    <Container center size={100} maxWidth="xs" background="#f7f4ef">
      <img src={StepFourImg} alt="weekly parsha inbox" className={classes.media} />
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Get the app!
      </Typography>
      <Typography variant="subtitle1" component="h2" align="center" gutterBottom>
        Enjoy Aleph Beta on the go! Enter your phone number below and we&lsquo;ll send you the link.
        <br />
        (Don&lsquo;t want to use SMS? No worries! Just search the app store for Aleph Beta!)
      </Typography>

      <form className={classes.form} onSubmit={handleSubmit}>
        {(isError || isValidationError) && (
          <MessageBox text={error || validationErrorMessage} isError />
        )}

        <Box display="flex" flexDirection="row">
          <TextInput
            key="phoneNumberInput"
            label="Your phone number"
            name="phone"
            type="tel"
            autoComplete="tel"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/*
                    Note that we don't use `inputRef={register}` on the `countryCode` because react-hook-form does not support `<TextInput select />` natively.
                    `onChange` props element handles integration instead of `inputRef` in this case. Everything has been setup in the parent component.
                    Also Prettier changes the code into the unreadable mess.
                  */}
                  <Select name="countryCode" value={countryCode} onChange={onCountryCodeChange}>
                    {availableCountryCodes.map(({ countryCode: aCountryCode, countryName }) => (
                      <MenuItem key={`${aCountryCode}|${countryName}`} value={aCountryCode}>
                        {aCountryCode}&nbsp;<small>({countryName})</small>
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              )
            }}
            inputRef={registerReactHookFormInput}
          />
        </Box>
        <ButtonSubmit>Text me the link</ButtonSubmit>
      </form>

      <Box pt={2}>
        <Link to={previousPath} state={previousLocationState}>
          Start exploring Aleph Beta
        </Link>
      </Box>
    </Container>
  );
};
OnboardingStepFour.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  isValidationError: PropTypes.bool,
  error: PropTypes.string,
  validationErrorMessage: PropTypes.string,
  availableCountryCodes: PropTypes.arrayOf(
    PropTypes.shape({ countryName: PropTypes.string, countryCode: PropTypes.string })
  ).isRequired,
  countryCode: PropTypes.string,
  onCountryCodeChange: PropTypes.func.isRequired,
  registerReactHookFormInput: PropTypes.func.isRequired, // @see react-hook-form's `register` function
  previousPath: PropTypes.string.isRequired,
  previousLocationState: PropTypes.shape({
    index: PropTypes.number,
    seekToSecond: PropTypes.number
  })
};

OnboardingStepFour.defaultProps = {
  isError: false,
  isValidationError: false,
  error: '',
  validationErrorMessage: '',
  countryCode: '+1',
  previousLocationState: {}
};

export default OnboardingStepFour;
