/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';

import useApi, { ONBOARDING_STEP_THREE } from '~hooks/useApi';
import { pushDataLayerEvent } from '~utils/data-layer';

import Container from '~components/Container';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import Link from '~components/Link';
import OnboardingRadioButtonInput from '~components/form-inputs/OnboardingRadio';
import MessageBox from '~components/forms/MessageBox';

const useStyles = makeStyles(theme => ({
  gridPosition: {
    marginTop: theme.spacing(2)
  },
  formStyle: {
    width: '100%',
    textAlign: 'center'
  }
}));

const SOURCES = ['Social Media', 'Web Search', 'Word of Mouth'];

const StepThree = () => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const [radioValue, setRadioValue] = useState('');
  const [isValidationError, setIsValidationError] = useState(false);
  const [{ data, isError, error }, makeRequest] = useApi(null, null);
  const validationErrorMessage = 'Choose a source to participate';

  useEffect(() => {
    if (data) {
      navigate('/onboarding/step-four');
    }
  }, [data]);

  const handleChange = event => {
    setRadioValue(event.target.value);
  };

  const onSubmit = ({ source }) => {
    if (!source) {
      return setIsValidationError(true);
    }

    pushDataLayerEvent('onboarding_step_submit', { onboardingStepNumber: '3' });

    const reqParams = ONBOARDING_STEP_THREE({ source: `Source - ${source}` });
    return makeRequest(reqParams);
  };

  return (
    <Container size={100} maxWidth="md" center background="#f7f4ef">
      <Typography variant="h4" component="h2" align="center" gutterBottom>
        How did you first hear about Aleph Beta?
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} className={classes.formStyle}>
        {(isError || isValidationError) && (
          <MessageBox text={error || validationErrorMessage} isError />
        )}

        <RadioGroup name="source" value={radioValue} onChange={handleChange}>
          <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
            {SOURCES &&
              SOURCES.map((source, index) => (
                <Grid item xs={12} md={12} key={`step-three-checkbox-${index}`}>
                  <OnboardingRadioButtonInput title={source} inputRef={register} />
                </Grid>
              ))}
          </Grid>
        </RadioGroup>

        <ButtonSubmit fullWidth={false} className={classes.gridPosition}>
          Continue
        </ButtonSubmit>
      </form>

      <Box pt={2}>
        <Link to="/onboarding/step-four">Skip this step</Link>
      </Box>
    </Container>
  );
};

export default StepThree;
