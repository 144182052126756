/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import useApi, { ONBOARDING_STEP_TWO } from '~hooks/useApi';

import Container from '~components/Container';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import Link from '~components/Link';
import OnboardingCheckboxInput from '~components/form-inputs/OnboardingCheckbox';
import MessageBox from '~components/forms/MessageBox';
import { pushDataLayerEvent } from '~src/utils/data-layer';

const useStyles = makeStyles(theme => ({
  gridPosition: {
    marginTop: theme.spacing(2)
  }
}));

const TOPICS = [
  'How to Mend a Broken Relationship',
  'Why Do We Celebrate Shabbat?',
  'How to Handle Anxiety',
  'Torah and Science',
  'Parenting',
  'The Tree of Knowledge',
  'The Meaning of Strange Torah Laws',
  'How to Read the Torah'
];

const StepTwo = () => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const [{ data, isError, error }, makeRequest] = useApi(null, null);
  const [isValidationError, setIsValidationError] = useState(false);
  const validationErrorMessage = 'Choose at least one topic to participate';

  useEffect(() => {
    if (data) {
      navigate('/onboarding/step-three');
    }
  }, [data]);

  const onSubmit = ({ topics }) => {
    const prefix = 'Interest - ';
    const filteredTopics = topics.filter(topic => typeof topic === 'string');

    if (!filteredTopics.length || filteredTopics.length < 1) {
      return setIsValidationError(true);
    }

    const prefixedTopics = filteredTopics.map(topic => prefix + topic);

    pushDataLayerEvent('onboarding_step_submit', { onboardingStepNumber: '2' });

    const reqParams = ONBOARDING_STEP_TWO({ topics: prefixedTopics });
    return makeRequest(reqParams);
  };

  return (
    <Container center size={100} maxWidth="md" background="#f7f4ef">
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Which of these topics might interest you?
      </Typography>
      <Typography variant="subtitle1" component="h2" align="center" gutterBottom>
        It helps us find more courses you will love!
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        {(isError || isValidationError) && (
          <MessageBox align="center" text={error || validationErrorMessage} isError />
        )}

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.gridPosition}
          spacing={3}
        >
          {TOPICS &&
            TOPICS.map((topic, index) => (
              <Grid item xs={12} md={6} key={`step-two-checkbox-${index}`}>
                <OnboardingCheckboxInput
                  title={topic}
                  name={`topics[${index}]`}
                  inputRef={register}
                />
              </Grid>
            ))}

          <Grid item>
            <ButtonSubmit className={classes.gridPosition}>Continue</ButtonSubmit>
          </Grid>
        </Grid>
      </form>

      <Box pt={2}>
        <Link to="/onboarding/step-three">Skip this step</Link>
      </Box>
    </Container>
  );
};

export default StepTwo;
