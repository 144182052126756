/** @deprecated Has been used in USA-only phone onboarding. */
export const formatPhoneNumber = phoneNumberString => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return false;
};

export const isPhoneNumberBelievable = number => {
  const digitsCount = String(number).replace(/\D/g, '').length;
  return digitsCount < 15 && digitsCount > 5;
};

const compareAscendingByCountryCodeName = (left, right) =>
  left.countryCode.localeCompare(right.countryCode);

const isUselessCountryData = countryData =>
  !countryData.countryCallingCodes || countryData.countryCallingCodes.length < 1;

const insertCountryCallingCodesInto = targetHashMap => countryData => {
  if (isUselessCountryData(countryData)) {
    return;
  }

  // Country can have several calling codes
  countryData.countryCallingCodes.forEach(code => {
    // This index is used to deduplicate the data.
    const index = `${code}|${countryData.name}`;
    if (!targetHashMap[index]) {
      const newCountryCodeRecord = {
        countryName: countryData.name,
        countryCode: code
      };

      // eslint-disable-next-line no-param-reassign
      targetHashMap[index] = newCountryCodeRecord;
    }
  });
};

/**
 * Please note that the raw data from the `country-data` is indexed by BOTH two-letter AND three-letter country codes, so it contains TWO records per each country.
 * Also please note that every country may have several assigned calling codes, hence the complexity of this function.
 *
 * @param {{ [countryAlphaCode: string]: { name: string, countryCallingCodes: Array<string> }}} countries Data directly from the `country-data` NPM package.
 * @returns {Array<{ countryCode: string, countryName: string}>} Data to be sent to the `OnboardingStepFour` component, usable to generate the dropdown data.
 */
export const makeCountryCodesDropdownData = countries => {
  const indexedCountryCodeDescriptions = {};
  Object.values(countries).forEach(insertCountryCallingCodesInto(indexedCountryCodeDescriptions));

  const dataWithoutIndices = Object.values(indexedCountryCodeDescriptions);

  const countryCodesDropdownData = dataWithoutIndices.sort(compareAscendingByCountryCodeName);

  return countryCodesDropdownData;
};
