import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';

import { countries } from 'country-data';

import useApi, { ONBOARDING_STEP_FOUR } from '~hooks/useApi';

import { pushDataLayerEvent } from '~utils/data-layer';

import { useFormWithControlledComponent } from '~utils/common';

import { makeCountryCodesDropdownData, isPhoneNumberBelievable } from '~utils/onboarding-helpers';
import { getPreviousLocationData } from '~utils/previous-path-cookie';
import OnboardingStepFour from '~src/components/onboarding/OnboardingStepFour';

// This variable initialization has to exist at this scope to not make this complicated calculation on every render.
const countryCodesDropdownData = makeCountryCodesDropdownData(countries);

const StepFour = () => {
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: { countryCode: '+1' }
  });
  const [{ data, isError, error }, makeRequest] = useApi(null, null);
  const [isValidationError, setIsValidationError] = useState(false);
  const validationErrorMessage = 'Enter a valid phone number';

  const { pathname: previousPath, state } = getPreviousLocationData();

  useEffect(() => {
    if (data) {
      navigate(previousPath, { state });
    }
  }, [data]); //eslint-disable-line

  const [countryCodeDropdownValue, onCountryCodeChange] = useFormWithControlledComponent({
    name: 'countryCode',
    register,
    watch,
    setValue
  });

  const onSubmit = ({ countryCode, phone }) => {
    const fullPhoneNumber = `${countryCode} ${phone}`;

    if (!isPhoneNumberBelievable(fullPhoneNumber)) {
      return setIsValidationError(true);
    }

    pushDataLayerEvent('onboarding_step_submit', { onboardingStepNumber: '4' });

    const reqParams = ONBOARDING_STEP_FOUR({
      phone: fullPhoneNumber
    });
    return makeRequest(reqParams);
  };

  return (
    <OnboardingStepFour
      handleSubmit={handleSubmit(onSubmit)}
      previousPath={previousPath}
      previousLocationState={state}
      countryCode={countryCodeDropdownValue}
      availableCountryCodes={countryCodesDropdownData}
      onCountryCodeChange={onCountryCodeChange}
      registerReactHookFormInput={register}
      isError={isError}
      error={error}
      isValidationError={isValidationError}
      validationErrorMessage={validationErrorMessage}
    />
  );
};

export default StepFour;
