import React from 'react';
import { Router } from '@reach/router';

import Page from '~layout/Page';
import RestrictedRoute from '~containers/restricted-route';
import StepOne from '~containers/onboarding/StepOne';
import StepTwo from '~containers/onboarding/StepTwo';
import StepThree from '~containers/onboarding/StepThree';
import StepFour from '~containers/onboarding/StepFour';
import { ACCESS_LEVELS } from '~utils/required-subscription-plan';

const Onboarding = () => {
  const minAccessLevel = ACCESS_LEVELS.LOGGED_IN;

  return (
    <Page>
      <Router>
        <RestrictedRoute
          path="/onboarding/step-one"
          component={StepOne}
          default
          minAccessLevel={minAccessLevel}
        />
        <RestrictedRoute
          path="/onboarding/step-two"
          component={StepTwo}
          minAccessLevel={minAccessLevel}
        />
        <RestrictedRoute
          path="/onboarding/step-three"
          component={StepThree}
          minAccessLevel={minAccessLevel}
        />
        <RestrictedRoute
          path="/onboarding/step-four"
          component={StepFour}
          minAccessLevel={minAccessLevel}
        />
      </Router>
    </Page>
  );
};

export default Onboarding;
