import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useApi, { ONBOARDING_STEP_ONE } from '~hooks/useApi';

import { pushDataLayerEvent } from '~utils/data-layer';

import Container from '~components/Container';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import Link from '~components/Link';

import StepOneImg from '~images/onboarding/step-1.jpg';

const useStyles = makeStyles(theme => ({
  media: {
    maxWidth: '100%',
    display: 'flex',
    marginBottom: theme.spacing(3)
  }
}));

const StepOne = () => {
  const classes = useStyles();
  const [{ data }, makeRequest] = useApi(null, null);
  const { handleSubmit } = useForm();

  useEffect(() => {
    if (data) {
      navigate('/onboarding/step-two');
    }
  }, [data]);

  const onSubmit = () => {
    const reqParams = ONBOARDING_STEP_ONE({ topics: ['Email Preference - WTP'] });
    makeRequest(reqParams);
    pushDataLayerEvent('onboarding_step_submit', { onboardingStepNumber: '1' });
  };

  return (
    <Container center size={100} maxWidth="xs" background="#f7f4ef">
      <img src={StepOneImg} alt="weekly parsha inbox" className={classes.media} />
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Weekly Parsha straight to your inbox
      </Typography>
      <Typography variant="subtitle1" component="h2" align="center" gutterBottom>
        Stay informed with the weekly Parsha by subscribing to our weekly Parsha video newsletter.
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ButtonSubmit>Sounds good</ButtonSubmit>
      </form>

      <Box pt={2}>
        <Link to="/onboarding/step-two">Skip this step</Link>
      </Box>
    </Container>
  );
};

export default StepOne;
